import { useEffect, useMemo, useState } from 'react'

import { Location } from 'Constants/billing'
import { CurrencyKey } from 'Constants/Landing/Pricing/prices'

import Utils from 'Services/Utils'

function useUserLocation() {
  const [location, setLocation] = useState(Location.Default)

  const currencyKey =
    location === Location.Au ? CurrencyKey.AUD : CurrencyKey.USD

  const currencySymbol = location === Location.Au ? 'A$' : '$'

  useEffect(() => {
    Utils.Location.getUserLocation().then(result =>
      setLocation(result === Location.Au ? Location.Au : Location.Default),
    )
  }, [])

  return useMemo(
    () => ({
      currencySymbol,
      currencyKey,
      location,
    }),
    [currencySymbol, currencyKey, location],
  )
}

export default useUserLocation
