async function getUserLocation() {
  return fetch('https://ipapi.co/json/', {
    method: 'GET',
  })
    .then(response => {
      return response.json()
    })
    .then(result => {
      return result.country_code
    })
}

export default {
  getUserLocation,
}
